<template>
  <div class="date-picker" ref="pickerDropdown">
    <!-- Campo de texto -->
   
    <v-text-field
      v-model="formattedDate"
      class="rounded"
      outlined
      readonly
      @focus="togglePicker(true)"
      dense
    ></v-text-field>

    <!-- Date Picker -->
    <div v-if="showPicker" class="picker-dropdown">
      <!-- Mes -->
      <div class="picker-column">
        <div class="infinite-scroll" @scroll="handleScroll($event, 'M')">
          <div
            v-for="(item, index) in visibleMonths"
            :key="index"
            :class="{
              active: index === selectedMonth,
              disabled:
                (index < currentMonth-1  && actualYearSelected) || backYearSelected,
            }"
            class="itemM"
            @click="selectMonth(index)"
          >
            <span class="d-flex justify-center"> {{ item }} </span>
            <v-divider class="mt-2"></v-divider>
          </div>
        </div>
        <!--   <ul ref="monthsList" @scroll="handleScroll">
            <li
              v-for="(month, index) in visibleMonths"
              :key="index"
              :class="{ active: index === selectedMonth }"
              @click="selectMonth(index)"
            >
              {{ month }}
            </li>
          </ul> -->
      </div>

      <!-- Año -->
      <div class="picker-column">
        <div class="infinite-scroll" @scroll="handleScroll($event, 'Y')">
          <div
            v-for="(item, index) in infiniteYears"
            :key="index"
            :class="{ active: index === selectedYear }"
            class="itemY"
            @click="selectYear(index)"
          >
            <span class="d-flex justify-center"> {{ item }}</span>
            <v-divider class="mt-2"></v-divider>
          </div>
        </div>
        <!-- <ul ref="yearsList">
            <li
              v-for="(year, index) in infiniteYears"
              :key="index"
              :class="{ active: index === selectedYear }"
              @click="selectYear(index)"
            >
              {{ year }}
            </li>
          </ul> -->
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    dateInitial: String,
    creating: Boolean,
  },
  data() {
    return {
      // Días, meses y años iniciales

      months: [
        "01 Jan",
        "02 Feb",
        "03 Mar",
        "04 Apr",
        "05 May",
        "06 Jun",
        "07 Jul",
        "08 Aug",
        "09 Sep",
        "10 Oct",
        "11 Nov",
        "12 Dec",
      ],
      monthsVisual: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      visibleMonths: [],
      currentMonth: Number(moment().add(1,'month').format("MM")),
      visibleYears: [],
      itemsPerPage: 10,
      currentPage: 0,
      // Desde 1900 hasta el año actual

      // Estado de la selección
      selectedDay: 1,
      selectedMonth: 0,
      indexMonth: 0,
      selectedYear: 0,

      showPicker: false, // Controla la visibilidad del picker
    };
  },
  computed: {
    years() {
      if (this.creating) {
        return Array.from({ length: 2 }, (_, i) => Number(moment().format("YYYY")) + i);
      } else {
        return Array.from(
          { length: 3 },
          (_, i) => Number(moment().add("month", -1).format("YYYY")) + i
        );
      }
    },
    actualYearSelected() {
      const year = Number(moment().format("YYYY"));
      const selecty = Number(this.years[this.selectedYear]);

      if (year == selecty) {
        return true;
      }
      return false;
    },
    backYearSelected() {
      const year = Number(moment().format("YYYY"));
      const selecty = Number(this.years[this.selectedYear]);

      if (year > selecty) {
        return true;
      }
      return false;
    },
    // Formato de fecha: "Día Mes Año"
    formattedDate() {
      return `${this.monthsVisual[this.selectedMonth]}, ${this.years[this.selectedYear]}`;
    },
    formattedDateM() {
      return `${this.months[this.selectedMonth]} ${this.years[this.selectedYear]}`;
    },
    // Datos infinitos para el scroll (duplicados para crear el efecto de bucles)

    infiniteMonths() {
      return [...this.months];
    },
    infiniteYears() {
      return [...this.years];
    },
  },
  watch: {
    selectedMonth() {
      if (this.selectMonth < this.currentMonth-1  && this.actualYearSelected) {
        return;
      } else {
        const x = moment(this.formattedDate).startOf("month").format("MM-DD-YYYY");

        this.$emit("dateDOB", x);
      }
    },

    selectedYear() {
      if (this.backYearSelected) {
        const [year, month, day] = this.dateInitial.split("-");

        this.selectedDay = day;
        const mindex = Number(month) - 1;
        this.indexMonth = mindex;
        this.selectedMonth = mindex;
        const y = this.infiniteYears.find((i) => i == year);
        this.selectedYear = this.infiniteYears.indexOf(y);
      }
      const x = moment(this.formattedDate).format("MM-DD-YYYY");
      this.$emit("dateDOB", x);
    },
  },
  methods: {
    // Toggle para mostrar/ocultar el picker
    togglePicker(state) {
      this.showPicker = state;
      this.$nextTick(() => {
        // Cuando seleccionamos un elemento, nos aseguramos de que se vea

        this.scrollToSelectedItem("month");
        this.scrollToSelectedItem("year");
      });
    },

    // Selección de día

    // Selección de mes
    selectMonth(index) {
      if (this.backYearSelected) {
        return;
      }
      if (index < this.currentMonth-1 && this.actualYearSelected) {
        return;
      }
      this.indexMonth = index;
      this.selectedMonth = index % this.months.length;
      this.$nextTick(() => {
        // Cuando seleccionamos un elemento, nos aseguramos de que se vea
        this.scrollToSelectedItem("month");
      });
    },

    // Selección de año
    selectYear(index) {
      const year = Number(moment().format("YYYY"));
      const selecty = Number(this.years[index]);
      const actualYearSelected = year == selecty;

      if (this.indexMonth < this.currentMonth-1  && actualYearSelected) {
        return;
      }
      this.selectedYear = index % this.years.length;
      this.$nextTick(() => {
        // Cuando seleccionamos un elemento, nos aseguramos de que se vea
        this.scrollToSelectedItem("year");
      });
    },

    clearableClick() {
      const [year, month, day] = this.dateInitial.split("-");

      this.selectedDay = day;
      const mindex = Number(month) - 1;
      this.indexMonth = mindex;
      this.selectedMonth = mindex;
      const y = this.infiniteYears.find((i) => i == year);
      this.selectedYear = this.infiniteYears.indexOf(y);
    },

    scrollToSelectedItem(type) {
      // Buscar el elemento seleccionado y centrarlo en el contenedor
      let selectedElement = "";
      switch (type) {
        case "month":
          selectedElement = this.$el.querySelector(`.itemM.active`);
          break;

        default:
          selectedElement = this.$el.querySelector(`.itemY.active`);
          break;
      }

      if (selectedElement) {
        selectedElement.scrollIntoView({
          behavior: "smooth", // Desplazamiento suave
          block: "center", // Centra el elemento seleccionado en el contenedor
        });
      }
    },

    // Cerrar el picker después de la selección
    closePicker() {
      this.showPicker = false;
    },
    handleClickOutside(event) {
      if (
        this.$refs.pickerDropdown &&
        !this.$refs.pickerDropdown.contains(event.target) &&
        event.target !== this.$refs.dateInput
      ) {
        this.closePicker();
      }
    },

    handleScroll(event, type) {
      const bottom =
        event.target.scrollHeight === event.target.scrollTop + event.target.clientHeight;
      /*  if (bottom) {
          if (type == "M") {
            this.repeatItemsM();
          } else if (type == "D") {
            this.repeatItemsD();
          } else {
            this.repeatItemsY();
          }
        } */
    },
    repeatItemsM() {
      // Añadimos los primeros 10 elementos otra vez al final del array para simular el scroll infinito
      this.visibleMonths = [...this.visibleMonths, ...this.months];
      // Hacemos que el scroll vuelva al inicio para crear el efecto de bucle infinito
      this.$nextTick(() => {
        const container = this.$el;
        container.scrollTop = 0;
      });
    },
  },
  mounted() {
    this.visibleMonths = [...this.months];
    if (this.dateInitial) {
      const [year, month, day] = this.dateInitial.split("-");

      this.selectedDay = day;
      const mindex = Number(month) - 1;
      this.indexMonth = mindex;
      this.selectedMonth = mindex;
      const y = this.infiniteYears.find((i) => i == year);
      this.selectedYear = this.infiniteYears.indexOf(y);
    } else {
      const m = Number(moment().add(1,'month').format("MM"));
      this.selectedDay = 1;
      this.selectedMonth = m == 12 ? 0 : m - 1;
      this.selectedYear = m == 12 ? 1 : 0;
    }
    document.addEventListener("click", this.handleClickOutside);
  },
  destroyed() {
    // Eliminar listener cuando el componente se destruya
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style lang="scss" scoped>
.itemM {
  cursor: pointer;
  margin: 5px 0px;
  height: 35px !important;
}
.itemY {
  cursor: pointer;
  margin: 5px 0px;
  height: 35px !important;
}
.itemM.active,
.itemY.active {
  font-size: 18px;
  font-weight: bold;
  color: #000;
}

.itemM.disabled {
  color: #333;
  text-decoration: line-through;
}

.infinite-scroll {
  width: 100%;
  justify-content: center;
  height: 200px;
}
.date-picker {
  position: relative;
  display: inline-block;
  width: 100%;
}

.date-input {
  width: 250px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.picker-dropdown {
  position: absolute;
  top: 60%;
  left: 0;
  width: 250px !important;
  display: flex;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 10px;
  border-radius: 4px;
}

.picker-column {
  flex: 1;
  height: 200px !important; /* Altura del área visible */
  overflow: scroll; /* Oculta las barras de desplazamiento */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.picker-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
  transform: translateY(-50%); /* Centramos el contenido */
  transition: transform 0.3s ease-in-out;
}

.picker-column li {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  color: #666;
}

.picker-column li:hover {
  color: #333;
}

.picker-column::-webkit-scrollbar {
  display: none;
}
</style>
